<template>
  <uikit-skeleton
    v-if="skeleton"
    class="tub-offer-card"
    v-bind="$attrs"
    active
    loading
  />
  <template v-else>
    <NuxtLink
      class="tub-offer-card u-text-decoration-none"
      no-prefetch
      :to="offerUrl"
      v-bind="$attrs"
      :target="target"
    >
      <div
        class="cover"
        :class="{ seen: offer.seen }"
        @click="handleCardClick"
      >
        <img
          v-if="hasImg"
          :src="imageSrc"
          :alt="title"
          loading="lazy"
          @error="imageLoaderErrorHandler"
        >
        <img
          v-else
          alt="Image non fournie"
          src="https://placehold.co/200?text=Horiz.io&font=open-sans"
          loading="lazy"
        >
        <div class="bg-cover">
          <div class="meta">
            <uikit-tag-new
              v-if="isExclusive"
              class="exclusive-tag"
              type="default"
            >
              Exclusivité
            </uikit-tag-new>
            <div
              class="favorite-cta"
              @click.prevent.stop="handleFavorite"
            >
              <uikit-button
                :key="isFavoriteHackFlag"
                type="link"
              >
                <template
                  v-if="tubV2FavoritesAndSearchesEnable"
                  #icon
                >
                  <uikit-heart-icon :class="{ active: isFavorite }" />
                </template>
              </uikit-button>
            </div>
          </div>
          <div class="published-since">
            Il y a {{ createdSince }}
          </div>
        </div>
      </div>
      <div class="description">
        <div class="bolder">
          <span>
            <span class="price">{{ price }} €</span>
            •
            <span class="lighter square-meter-price">{{ prixm2 }}€/m2</span>
          </span>
          <span class="performance">{{ rendement }}% brut</span>
        </div>
        <div class="lighter">
          {{ line2 }}
        </div>
        <div class="lighter">
          {{ line3 }}
        </div>
      </div>
    </NuxtLink>
  </template>
</template>

<script setup>
import { UikitButton, UikitHeartIcon, UikitSkeleton, UikitTagNew } from '@hz/ui-kit'
import { useTubStore } from '../../stores/tub'
import { useFeaturesStore } from '~/stores/features.js'

const props = defineProps({
  offer: {
    type: Object,
    default: () => ({}),
  },
  skeleton: {
    type: Boolean,
    default: false,
  },
})

const tubV2FavoritesAndSearchesEnable = computed(() => useFeaturesStore().tubV2FavoritesAndSearchesEnable)

const tubStore = useTubStore()

const emit = defineEmits(['card-click', 'remove-favorite'])

const isFavorite = ref(props.offer.favorite)
const isFavoriteHackFlag = ref(0)

const isExclusive = computed(() => props.offer.hzExclusive)
const offerUrl = computed(() => {
  if (isExclusive.value) {
    return {
      name: 'ou-investir-annonces-id',
      params: { id: props.offer.id },
    }
  } else {
    return props.offer.permalien
  }
})
const target = computed(() => (isExclusive.value ? '_self' : '_blank'))

const imgInError = ref(false)
const hasImg = computed(
  () => !imgInError.value && !!props.offer?.images_url[0] && !props.offer.images_url[0].match(/^http\b/),
)
const imageSrc = computed(() => props.offer?.images_url[0])
const title = computed(() => props.offer.title ?? '')
const price = computed(() => props.offer?.prix?.toLocaleString())
const rendement = computed(() => props.offer?.rendement?.toLocaleString())
const prixm2 = computed(() => props.offer?.prixm2?.toLocaleString())
const line2 = computed(
  () =>
    `Se loue ${props.offer?.loyer}€/mois • ${capitalizeFirstLetter(props.offer?.type)} de ${
      props.offer?.surface
    }m2`,
)
const line3 = computed(() => `${capitalizeFirstLetter(props.offer?.ville)} (${props?.offer.cp})`)

const createdSince = computed(() => {
  const datetime = new Date(props.offer?.creation_date)
  if (datetime.getTime && !isNaN(datetime.getTime())) {
    const now = new Date()
    const difference = now - datetime
    const seconds = Math.floor(difference / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)

    if (days > 0) {
      return `${days} jour${days > 1 ? 's' : ''}`
    } else if (hours > 0) {
      return `${hours} heure${hours > 1 ? 's' : ''}`
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''}`
    } else {
      return 'un instant'
    }
  }

  return ''
})

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const imageLoaderErrorHandler = () => {
  imgInError.value = true
}

const handleFavorite = async () => {
  if (isFavorite.value) {
    const confirmed = await tubStore.removeToFavorites(props.offer.id)
    if (confirmed) {
      emit('remove-favorite', props.offer)
      isFavorite.value = false
    }
  } else {
    await tubStore.addToFavorites(props.offer.id)
    isFavorite.value = true
  }
}

const handleCardClick = () => {
  emit('card-click', props.offer)
}

onMounted(() => {
  isFavoriteHackFlag.value++
  isFavorite.value = props.offer.favorite
})

watch(
  () => props.offer,
  () => {
    isFavorite.value = props.offer.favorite
  },
)
</script>

<style lang="less" scoped>
@keyframes heart-beat {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.25);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.tub-offer-card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 342px;
  height: 240px;
  border-radius: 8px;
  border: 1px solid var(--ds-color-gray-25);
  background-color: var(--ds-color-white-100);
  cursor: pointer;

  .cover {
    position: relative;
    height: 140px;
    width: 100%;
    border-radius: 8px 8px 0 0;

    &.seen {
      opacity: 0.5;
      transition: all 0.3s ease;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px 8px 0 0;
    }

    .bg-cover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0px -40px 24px 0px rgba(0, 0, 0, 0.45) inset;
    }

    .meta {
      position: absolute;
      top: 16px;
      left: 20px;
      right: 20px;
      display: flex;
      justify-content: space-between;

      .favorite-cta {
        :deep(button) {
          height: auto;
        }

        :deep(.icon) {
          height: 24px;
          width: 24px;
          transition: all 0.25s cubic-bezier(0.23, 1, 0.32, 1);

          justify-content: center;
          align-items: center;

          svg {
            height: 100%;
            width: 100%;
            transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);

            path {
              transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
              fill: var(--ds-color-gray-25);
              stroke: var(--ds-color-gray-50);
            }

            &:not(.active):hover {
              path {
                fill: var(--ds-color-error-25);
                stroke: var(--ds-color-error-100);
              }
            }

            &.active {
              animation: heart-beat 1s;
              animation-iteration-count: 1;
              path {
                fill: var(--ds-color-error-100);
                stroke: var(--ds-color-error-100);
              }
            }
          }
        }
      }
    }

    .published-since {
      position: absolute;
      bottom: 12px;
      right: 16px;
      color: var(--ds-color-gray-25);
      font-size: 14px;
      font-weight: var(--ds-weight-regular);
    }

    :deep(.uikit-tag) {
      font-size: 12px;
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    padding: 16px 20px;
    height: 100px;
    gap: 4px;

    > div {
      display: flex;
      flex: 1;
      justify-content: space-between;
    }

    .bolder {
      color: var(--ds-color-gray-100);
      font-size: 16px;
      font-weight: var(--ds-weight-semi-bold);
    }

    .lighter {
      color: var(--ds-color-gray-50);
      font-size: 14px;
      font-weight: var(--ds-weight-regular);
    }
  }
}
</style>
