import { defineStore } from 'pinia'

export const useFeaturesStore = defineStore('home-features', {
  state: () => ({}),
  getters: {
    tubV2FavoritesAndSearchesEnable: () => {
      return true
    },
  },
})
