import { shallowRef } from 'vue'
import { defineStore } from 'pinia'
import { useTubApi } from '../composables/tub-api'
import { useDrawerStore, useModalStore } from '@hz/ui-kit'
import { useAuthStore } from './auth'
import DrawerEditRealEstateOfferSearch from '../components/Tub/DrawerEditRealEstateOfferSearch.vue'
import DrawerMyRealEstateOfferFavorites from '../components/Tub/DrawerMyRealEstateOfferFavorites.vue'
import DrawerMyRealEstateOfferSearches from '../components/Tub/DrawerMyRealEstateOfferSearches.vue'
import DrawerSearch from '../components/Tub/DrawerSearch.vue'

export const useTubStore = defineStore('home-tub', {
  state: () => ({
    favoritesIds: [],
    seens: [],
  }),
  actions: {
    async getFavoritesIds() {
      const authStore = useAuthStore()
      if (authStore.authenticated) {
        this.favoritesIds = await useTubApi().getFavoritesIds()
      }
    },
    async addToFavorites(offerId) {
      const authStore = useAuthStore()
      if (authStore.authenticated) {
        await useTubApi().addToFavorites(offerId)
        await this.getFavoritesIds()
      } else {
        await authStore.register()
      }
    },
    async removeToFavorites(offerId) {
      return new Promise((resolve) => {
        useModalStore().confirm({
          title: 'Supprimer un favoris',
          message: 'Souhaitez-vous supprimer votre favoris ?',
          validateText: 'Supprimer',
          onConfirmed: async () => {
            await useTubApi().removeToFavorites(offerId)
            await this.getFavoritesIds()
            resolve(true)
          },
          onCanceled: () => {
            resolve(false)
          },
        })
      })
    },
    async getSeens() {
      const authStore = useAuthStore()
      if (authStore.authenticated) {
        const seens = await useTubApi().getSeens()
        this.seens = seens.history
      }
    },
    async flagAsSeen(realEstateOfferId) {
      const authStore = useAuthStore()
      if (authStore.authenticated) {
        await useTubApi().flagAsSeen(realEstateOfferId)
        this.seens.push(realEstateOfferId)
      }
    },
    async openMySearches() {
      const authStore = useAuthStore()
      if (authStore.authenticated) {
        useDrawerStore().addDrawer({
          id: 'drawer-my-real-estate-offer-searches',
          component: shallowRef(DrawerMyRealEstateOfferSearches),
        })
      } else {
        await authStore.register()
      }
    },
    async openMyFavorites() {
      const authStore = useAuthStore()
      if (authStore.authenticated) {
        useDrawerStore().addDrawer({
          id: 'drawer-my-real-estate-offer-favorites',
          component: shallowRef(DrawerMyRealEstateOfferFavorites),
        })
      } else {
        await authStore.register()
      }
    },
    async openSaveSearchDrawer(filters, sorting) {
      const authStore = useAuthStore()
      const router = useRouter()

      if (authStore.authenticated) {
        useDrawerStore().addDrawer({
          id: 'drawer-create-real-estate-offer-search',
          component: shallowRef(DrawerEditRealEstateOfferSearch),
          props: {
            updateMode: false,
            realEstateOfferSearch: null,
            search: {
              filters,
              sorting,
            },
            onSuccess: async (search) => {
              await router.push({
                name: 'biens-rentables-custom-search',
                query: {
                  searchId: search.id,
                },
              })
            },
          },
        })
      } else {
        await authStore.register()
      }
    },
    async openEditSearchDrawer(search, props) {
      const authStore = useAuthStore()
      if (authStore.authenticated) {
        useDrawerStore().addDrawer({
          id: 'drawer-edit-real-estate-offer-search',
          component: shallowRef(DrawerEditRealEstateOfferSearch),
          props: {
            ...props,
            updateMode: true,
            realEstateOfferSearch: search,
            search: search.search,
          },
        })
      } else {
        await authStore.register()
      }
    },
    openTubDrawerSearch(filters, handleUpdateFilters) {
      useDrawerStore().addDrawer({
        id: 'tub-drawer-search',
        component: shallowRef(DrawerSearch),
        props: {
          filters: filters,
          updateFilters: handleUpdateFilters,
        },
      })
    },
  },
})
