<template>
  <uikit-drawer id="drawer-ad-agency-contact" size="MEDIUM" title="Rechercher un bien">
    <uikit-form-row one-column class="u-gap-8">
      <h2>Critères principaux</h2>
      <div>Saisissez les informations cruciales de votre recherche</div>
    </uikit-form-row>

    <uikit-form-row one-column>
      <uikit-form-cell title="Localisation">
        <uikit-autocomplete-new
          id="location"
          v-model="citiesSearchQuery"
          class="locations-selector"
          name="location"
          standalone
          placeholder="Rechercher..."
          :options="citiesSearchOptions"
          :loading="citiesSearchLoading"
          @update:model-value="locationUpdateHandler"
          @on-select-option="locationSelectHandler"
        >
          <template v-if="ciyTags.length" #prefix>
            <uikit-tag-list :tags="ciyTags" class="location-selector">
              <template #tag="{ tag }">
                <uikit-close-icon class="cross-icon" @click.native="handleRemoveLocation($event, tag)" />
                <span class="separator">|</span>
                {{ cityFormatter(tag) }}
              </template>
            </uikit-tag-list>
          </template>

          <template #option="option">
            {{ cityFormatter(option) }}
          </template>

          <template v-if="onlyOneCitySelected" #suffix>
            <div class="separator" />
            <uikit-dropdown ref="distanceController" class="u-dis-flex-inline" :forceCloseOnClick="true">
              <span><uikit-map-point-icon class="u-icon-m" /></span>
              <template #overlay>
                <uikit-menu>
                  <template v-for="distanceOption in distanceOptions" :key="distanceOption.value">
                    <uikit-menu-item
                      :id="`distance-${distanceOption.value}`"
                      :class="{
                        active: distanceOption.value === cityRangeDistance,
                      }"
                      @click="handleSetDistance(distanceOption.value)"
                    >
                      {{ distanceOption.label }}
                    </uikit-menu-item>
                  </template>
                </uikit-menu>
              </template>
            </uikit-dropdown>
          </template>
        </uikit-autocomplete-new>
      </uikit-form-cell>
    </uikit-form-row>
    <uikit-form-row>
      <uikit-form-cell title="Rendement brut">
        <uikit-input-price-new id="rendementMin" name="rendementMin" placeholder="Min" :min="0" :max="100">
          <template #suffix>
            <span class="suffix">%</span>
          </template>
        </uikit-input-price-new>
      </uikit-form-cell>
      <uikit-form-cell empty-title>
        <uikit-input-price-new id="rendementMax" name="rendementMax" placeholder="Max" :min="0" :max="100">
          <template #suffix>
            <span class="suffix">%</span>
          </template>
        </uikit-input-price-new>
      </uikit-form-cell>
    </uikit-form-row>
    <uikit-form-row>
      <uikit-form-cell title="Prix du bien">
        <uikit-input-price-new id="prixMin" name="prixMin" placeholder="Min">
          <template #suffix>
            <span class="suffix">€</span>
          </template>
        </uikit-input-price-new>
      </uikit-form-cell>
      <uikit-form-cell empty-title>
        <uikit-input-price-new id="prixMax" name="prixMax" placeholder="Max">
          <template #suffix>
            <span class="suffix">€</span>
          </template>
        </uikit-input-price-new>
      </uikit-form-cell>
    </uikit-form-row>
    <uikit-form-row one-column>
      <uikit-form-cell title="Types d’annonces">
        <uikit-input-fluids
          id="adType"
          name="adType"
          :options="[
            { label: 'Toutes', value: 'all' },
            { label: 'Exclusives', value: 'exclusive' },
          ]"
        />
      </uikit-form-cell>
    </uikit-form-row>

    <uikit-form-row one-column class="u-gap-8">
      <h2>Critères avancés</h2>
      <div>Donnez-nous plus d’informations sur votre recherche</div>
    </uikit-form-row>
    <uikit-form-row one-column>
      <uikit-form-cell
        title="Taille de ville"
        hint="Petite ville (moins de  20 000 habitants), Moyenne (entre 20 000 et 200 000 habitants), Grande (plus de 200 000 habitants)"
      >
        <uikit-input-fluids id="citySize" name="citySize" :options="citySizeTypes" multiSelect />
      </uikit-form-cell>
    </uikit-form-row>
    <uikit-form-row>
      <uikit-form-cell title="Prix m2">
        <uikit-input-price-new id="prixm2Min" name="prixm2Min" placeholder="Min">
          <template #suffix>
            <span class="suffix">€</span>
          </template>
        </uikit-input-price-new>
      </uikit-form-cell>
      <uikit-form-cell empty-title>
        <uikit-input-price-new id="prixm2Max" name="prixm2Max" placeholder="Max">
          <template #suffix>
            <span class="suffix">€</span>
          </template>
        </uikit-input-price-new>
      </uikit-form-cell>
    </uikit-form-row>
    <uikit-form-row>
      <uikit-form-cell title="Loyer">
        <uikit-input-price-new id="loyerMin" name="loyerMin" placeholder="Min">
          <template #suffix>
            <span class="suffix">€</span>
          </template>
        </uikit-input-price-new>
      </uikit-form-cell>
      <uikit-form-cell empty-title>
        <uikit-input-price-new id="loyerMax" name="loyerMax" placeholder="Max">
          <template #suffix>
            <span class="suffix">€</span>
          </template>
        </uikit-input-price-new>
      </uikit-form-cell>
    </uikit-form-row>

    <uikit-form-row one-column>
      <uikit-form-cell title="Stratégie de location">
        <uikit-input-select
          id="recommendedStrategies"
          name="recommendedStrategies"
          :options="realEstateAdRentStrategyOptions"
          :close-on-selection="false"
          multiple
          placeholder="Sélectionner une ou plusieurs stratégies"
        />
      </uikit-form-cell>
    </uikit-form-row>
    <uikit-form-row one-column separator>
      <uikit-form-cell title="Régime fiscal préconisé">
        <uikit-input-select
          id="preferredRegime"
          name="preferredRegime"
          :options="preferredRegimeTypes"
          :close-on-selection="false"
          multiple
          placeholder="Sélectionner un ou plusieurs régimes"
        />
      </uikit-form-cell>
    </uikit-form-row>
    <uikit-form-row>
      <uikit-form-cell title="Surface">
        <uikit-input-price-new id="surfaceMin" name="surfaceMin" placeholder="Min">
          <template #suffix>
            <span class="suffix">m²</span>
          </template>
        </uikit-input-price-new>
      </uikit-form-cell>
      <uikit-form-cell empty-title>
        <uikit-input-price-new id="surfaceMax" name="surfaceMax" placeholder="Max">
          <template #suffix>
            <span class="suffix">m²</span>
          </template>
        </uikit-input-price-new>
      </uikit-form-cell>
    </uikit-form-row>
    <uikit-form-row>
      <uikit-form-cell title="Nombre de pièces">
        <uikit-input-price-new id="nbpiecesMin" name="nbpiecesMin" :min="0" :max="100" placeholder="Min" />
      </uikit-form-cell>
      <uikit-form-cell empty-title>
        <uikit-input-price-new id="nbpiecesMax" name="nbpiecesMax" :min="0" :max="100" placeholder="Max" />
      </uikit-form-cell>
    </uikit-form-row>
    <uikit-form-row one-column>
      <uikit-form-cell title="Etage du bien">
        <uikit-input-fluids id="floor" name="floor" :options="realEstateFloorTypes" multiSelect />
      </uikit-form-cell>
    </uikit-form-row>
    <uikit-form-row one-column>
      <uikit-form-cell title="Sélectionner le type de logement">
        <uikit-input-fluids id="type" name="type" :options="realEstateTypes" multiSelect />
      </uikit-form-cell>
    </uikit-form-row>
    <uikit-form-row one-column separator>
      <uikit-form-cell title="Propriété">
        <uikit-input-fluids id="coOwnership" name="coOwnership" :options="ownershipTypes" />
      </uikit-form-cell>
    </uikit-form-row>
    <uikit-form-row one-column>
      <uikit-form-cell title="Etat du bien">
        <uikit-input-fluids id="neuf" name="neuf" :options="realEstateState" />
      </uikit-form-cell>
    </uikit-form-row>
    <uikit-form-row one-column separator>
      <uikit-form-cell title="Sélectionner les spécificités">
        <uikit-input-fluids
          id="specificities"
          name="specificities"
          :options="realEstateSpecificities"
          multiSelect
        />
      </uikit-form-cell>
    </uikit-form-row>
    <uikit-form-row one-column>
      <uikit-form-cell title="DPE">
        <uikit-input-fluids
          id="energyClass"
          name="energyClass"
          :options="realEstateEnergyClassOptions"
          multiSelect
        />
      </uikit-form-cell>
    </uikit-form-row>
    <uikit-form-row one-column separator>
      <uikit-form-cell title="GES">
        <uikit-input-fluids id="ges" name="ges" :options="realEstateGesOptions" multiSelect />
      </uikit-form-cell>
    </uikit-form-row>

    <uikit-form-row :separator="isAdmin || values.agencies?.length > 0 || values.tags?.length > 0">
      <uikit-form-cell title="Ancienneté des annonces">
        <uikit-input-price-new
          id="freshnessMin"
          name="freshnessMin"
          :min="0"
          :max="100"
          placeholder="Supérieur ou égale"
        >
          <template #suffix>
            <span class="suffix">jour(s)</span>
          </template>
        </uikit-input-price-new>
      </uikit-form-cell>
      <uikit-form-cell empty-title>
        <uikit-input-price-new
          id="freshnessMax"
          name="freshnessMax"
          :min="0"
          :max="100"
          placeholder="Inférieure ou égale"
        >
          <template #suffix>
            <span class="suffix">jour(s)</span>
          </template>
        </uikit-input-price-new>
      </uikit-form-cell>
    </uikit-form-row>

    <uikit-form-row v-if="isAdmin || values.agencies?.length > 0" one-column>
      <uikit-form-cell title="Agences">
        <uikit-autocomplete-new
          v-if="isAdmin"
          id="agency"
          v-model="agenciesSearchQuery"
          class="locations-selector"
          name="agency"
          standalone
          placeholder="Rechercher..."
          :options="agenciesSearchOptions"
          :loading="agenciesSearchLoading"
          @update:model-value="agencyUpdateHandler"
          @on-select-option="agencySelectHandler"
        >
          <template v-if="agenciesTags.length" #prefix>
            <uikit-tag-list :tags="agenciesTags" class="location-selector">
              <template #tag="{ tag }">
                <uikit-close-icon class="cross-icon" @click.native="handleRemoveAgency($event, tag)" />
                <span class="separator">|</span>
                {{ agencyFormatter(tag) }}
              </template>
            </uikit-tag-list>
          </template>

          <template #option="option">
            {{ agencyFormatter(option) }}
          </template>
        </uikit-autocomplete-new>
        <template v-else>
          <div>
            Filtré sur une sélection d'agences
            <span class="cta-separator" />
            <uikit-button type="link" @click="handleRemoveAgencies">supprimer</uikit-button>
          </div>
        </template>
      </uikit-form-cell>
    </uikit-form-row>
    <uikit-form-row v-if="isAdmin || values.tags?.length > 0" one-column>
      <uikit-form-cell title="Tags">
        <uikit-autocomplete-new
          v-if="isAdmin"
          id="tag"
          v-model="tagsSearchQuery"
          name="tag"
          class="locations-selector"
          standalone
          placeholder="Rechercher..."
          :options="tagsSearchOptions"
          :loading="tagsSearchLoading"
          @update:model-value="handleUpdateTag"
          @on-select-option="handleSelectTag"
        >
          <template v-if="tags.length" #prefix>
            <uikit-tag-list :tags="tags" class="location-selector">
              <template #tag="{ tag }">
                <uikit-close-icon class="cross-icon" @click.native="handleRemoveTag($event, tag)" />
                <span class="separator">|</span>
                {{ tagFormatter(tag) }}
              </template>
            </uikit-tag-list>
          </template>

          <template #option="option">
            {{ tagFormatter(option) }}
          </template>
        </uikit-autocomplete-new>
        <template v-else>
          <div>
            Filtré sur une sélection d'annonces
            <span class="cta-separator" />
            <uikit-button type="link" @click="handleRemoveOfferSelection">supprimer</uikit-button>
          </div>
        </template>
      </uikit-form-cell>
    </uikit-form-row>

    <template #footer>
      <uikit-button
        v-if="displayResetFilters"
        type="link"
        :loading="isSubmitting"
        :disabled="isSubmitting"
        @click="handleResetFilters"
      >
        Réinitialiser
      </uikit-button>
      <uikit-button
        class="main-cta"
        type="primary"
        :loading="isSubmitting"
        :disabled="isSubmitting"
        :inactive="!meta.valid || isSubmitting"
        @click="handleApplyFilters"
      >
        Valider
      </uikit-button>
    </template>
  </uikit-drawer>
</template>

<script setup>
import {
  UikitAutocompleteNew,
  UikitButton,
  UikitCloseIcon,
  UikitDrawer,
  UikitFormCell,
  UikitFormRow,
  UikitInputFluids,
  UikitInputPriceNew,
  UikitTagList,
  UikitInputSelect,
  UikitMapPointIcon,
  useDrawerStore,
  UikitDropdown,
  UikitMenu,
  UikitMenuItem,
} from '@hz/ui-kit'

import { useForm, defineRule } from 'vee-validate'
import { useFormHelpers } from '../../helpers/form'
import {
  realEstateAdRentStrategyOptions,
  preferredRegimeTypes,
  realEstateTypes,
  ownershipTypes,
  realEstateState,
  realEstateSpecificities,
  realEstateEnergyClassOptions,
  realEstateGesOptions,
  realEstateFloorTypes,
  citySizeTypes,
} from '../../helpers/real-estates'
import { useAuthStore } from '../../stores/auth'

const props = defineProps({
  filters: {
    type: Object,
    required: true,
  },
  updateFilters: {
    type: Function,
    required: true,
  },
})

const distanceOptions = [
  { label: '0 km', value: 0 },
  { label: '10 km', value: 10 },
  { label: '20 km', value: 20 },
  { label: '50 km', value: 50 },
  { label: '100 km', value: 100 },
  { label: '150 km', value: 150 },
  { label: '200 km', value: 200 },
  { label: 'Département', value: 'department' },
  { label: 'Région', value: 'region' },
]

const distanceController = ref()

const handleSetDistance = (value) => {
  cityRangeDistance.value = value
  distanceController.value.hide()
}

const attrs = useAttrs()

const cityRangeDistance = ref(null)

const citiesSearchQuery = ref('')
const citiesSearchOptions = ref([])
const citiesSearchLoading = ref(false)

const agenciesSearchQuery = ref('')
const agenciesSearchOptions = ref([])
const agenciesSearchLoading = ref(false)

const tagsSearchQuery = ref('')
const tagsSearchOptions = ref([])
const tagsSearchLoading = ref(false)

const isAdmin = computed(() => useAuthStore().isAdmin)

defineRule('freshnessRuleMin', (value, [target]) => {
  if (!value || !target) {
    return true
  }

  if (value <= target) {
    return true
  }

  return `Doit être inférieure ou égale à ${target}`
})

defineRule('freshnessRuleMax', (value, [target]) => {
  if (!value || !target) {
    return true
  }

  if (value >= target) {
    return true
  }

  return `Doit être supérieur ou égale à ${target}`
})

const validationSchema = {
  // Regular filters
  locations: {},
  rendementMin: {},
  rendementMax: {},
  prixMin: {},
  prixMax: {},
  adType: {},

  // Advanced filters
  prixm2Min: {},
  prixm2Max: {},
  loyerMin: {},
  loyerMax: {},
  preferredRegime: {},
  recommendedStrategies: {},
  surfaceMin: {},
  surfaceMax: {},
  type: {},
  coOwnership: {},
  neuf: {},
  floor: {},
  nbpiecesMin: {},
  nbpiecesMax: {},
  specificities: {},
  energyClass: {},
  ges: {},
  agencies: {},
  citySize: {},
  freshnessMin: { freshnessRuleMin: '@freshnessMax' },
  freshnessMax: { freshnessRuleMax: '@freshnessMin' },
  tags: {},
}

const formHelper = useFormHelpers(validationSchema)

const initialFilers = {
  ...props.filters,
}

if (initialFilers.locations?.length === 1) {
  cityRangeDistance.value = initialFilers.locations[0].distance
}

if (initialFilers.tags?.length > 0) {
  initialFilers.tags = initialFilers.tags.map((tag) => ({ name: tag }))
}

const { handleSubmit, isSubmitting, values, setFieldValue, setValues, meta } = useForm({
  validationSchema,
  initialValues: formHelper.getOnlyFormFields(initialFilers),
})

const onlyOneCitySelected = computed(() => {
  return values.locations?.length === 1
})

const ciyTags = computed(() => {
  if (!values.locations) {
    return []
  }

  if (onlyOneCitySelected.value) {
    return [
      {
        type: 'default',
        ...values.locations[0],
        distance: cityRangeDistance.value !== null ? cityRangeDistance.value : values.locations[0].distance,
      },
    ]
  }

  return values.locations.map((location) => ({
    type: 'default',
    ...location,
  }))
})

const agenciesTags = computed(() => {
  if (!values.agencies) {
    return []
  }

  return values.agencies.map((agency) => ({
    type: 'default',
    ...agency,
  }))
})

const tags = computed(() => {
  if (!values.tags) {
    return []
  }

  return values.tags.map((tag) => ({
    type: 'default',
    ...tag,
  }))
})

const displayResetFilters = computed(() => {
  return Object.keys(values).some((key) => {
    if (Array.isArray(values[key])) {
      return values[key].length > 0
    } else {
      return values[key] !== undefined && values[key] !== null && values[key] !== ''
    }
  })
})

const cityFormatter = (option) => {
  if (option.distance === 'region') {
    return `Région - ${option.ville} (${option.cp})`
  } else if (option.distance === 'department') {
    return `Département - ${option.ville} (${option.cp})`
  } else if (option.distance === 'all-city') {
    return `${option.ville} (Toute la ville)`
  } else if (option.distance !== 0 && option.distance !== '0') {
    return `${option.ville} (${option.cp}) à ${option.distance} km`
  } else {
    return `${option.ville} (${option.cp})`
  }
}

const locationUpdateHandler = async (query) => {
  citiesSearchLoading.value = true
  const results = await useCitiesApi().getCities(query)
  citiesSearchOptions.value = results
    .map((result) => ({
      cp: result.postalCode,
      ville: result.locality,
      distance: result.hasMultiplePostalCodes ? 'all-city' : 0,
    }))
    .filter((result) => !values.locations?.some((l) => areSameLocations(l, result)))
  citiesSearchLoading.value = false
}

const locationSelectHandler = (location) => {
  if (values.locations?.find((l) => areSameLocations(l, location))) {
    return
  }

  const newLocations = [...(values.locations ?? []), location]
  if (values.locations?.length === 1 && newLocations.length === 2) {
    cityRangeDistance.value = null
    const previousCity = newLocations[0]
    if (
      previousCity.distance === 'region' ||
      previousCity.distance === 'department' ||
      (previousCity.distance !== 0 && previousCity.distance !== '0')
    ) {
      previousCity.distance = 0
    }
  }

  setFieldValue('locations', newLocations)
}

const areSameLocations = (location1, location2) => {
  return (
    location1.cp === location2.cp &&
    location1.ville === location2.ville &&
    location1.distance === location2.distance
  )
}

const handleRemoveLocation = (e, location) => {
  e.preventDefault()
  e.stopPropagation()
  if (onlyOneCitySelected.value) {
    setFieldValue('locations', [])
    cityRangeDistance.value = null
  } else {
    setFieldValue(
      'locations',
      values.locations.filter((l) => !areSameLocations(l, location)),
    )
  }
}

const agencyFormatter = (option) => {
  return `${option.name}`
}

const areSameAgencies = (agencyA, agencyB) => {
  return agencyA.id === agencyB.id
}

const agencyUpdateHandler = async (query) => {
  if (!query) {
    agenciesSearchOptions.value = []

    return
  }
  agenciesSearchLoading.value = true
  const results = await useAgenciesApi().searchAgencies({
    'identity.companyName': query,
  })
  agenciesSearchOptions.value = results.data
    .map((result) => ({
      id: result.id,
      name: result.identity.companyName,
    }))
    .filter((result) => !values.agencies?.some((l) => areSameAgencies(l, result)))
  agenciesSearchLoading.value = false
}

const agencySelectHandler = (agency) => {
  if (values.agencies?.find((l) => areSameAgencies(l, agency))) {
    return
  }

  const newAgencies = [...(values.agencies ?? []), agency]

  setFieldValue('agencies', newAgencies)
}

const handleRemoveAgency = (e, agency) => {
  e.preventDefault()
  e.stopPropagation()

  setFieldValue(
    'agencies',
    !values.agencies ? [] : values.agencies.filter((l) => !areSameAgencies(l, agency)),
  )
}

const handleRemoveAgencies = () => {
  setFieldValue('agencies', [])
}

const handleRemoveOfferSelection = () => {
  setFieldValue('tags', [])
}

const tagFormatter = (option) => {
  return `${option.name}`
}

const areSameTags = (tagA, tagB) => {
  return tagA.name === tagB.name
}

const handleUpdateTag = async (query) => {
  if (!query) {
    tagsSearchOptions.value = []

    return
  }
  tagsSearchLoading.value = true
  const results = await useAdsApi().searchTags({
    name: query,
  })
  tagsSearchOptions.value = results.data
    .map((result) => ({
      name: result.name,
    }))
    .filter((result) => !values.tags?.some((l) => areSameTags(l, result)))
  tagsSearchLoading.value = false
}

const handleSelectTag = (tag) => {
  if (values.tags?.find((l) => areSameTags(l, tag))) {
    return
  }

  setFieldValue('tags', [...(values.tags ?? []), tag])
}

const handleRemoveTag = (e, tag) => {
  e.preventDefault()
  e.stopPropagation()

  setFieldValue('tags', !values.tags ? [] : values.tags.filter((l) => !areSameTags(l, tag)))
}

const close = () => {
  useDrawerStore().removeDrawer({ id: attrs.id })
}

const handleApplyFilters = handleSubmit((values) => {
  const filters = formHelper.getOnlyFormFields(values)
  if (onlyOneCitySelected.value) {
    filters.locations[0].distance =
      cityRangeDistance.value !== null ? cityRangeDistance.value : filters.locations[0].distance
  }
  if (values.tags && values.tags.length > 0) {
    filters.tags = filters.tags.map((tag) => tag.name)
  }
  props.updateFilters(filters)
  close()
})

const handleResetFilters = handleSubmit(async (values) => {
  const newValues = {}
  Object.keys(values).forEach((key) => {
    newValues[key] = undefined
  })

  cityRangeDistance.value = null

  setValues(newValues)
})
</script>

<style lang="less" scoped>
.location-selector {
  .cross-icon {
    width: 9px;
    height: 9px;
    margin-top: 1px;
    margin-left: 6px;
    align-self: center;
    color: var(--ds-color-primary-100);
  }
  .separator {
    margin-left: 4px;
    margin-right: 4px;
    color: var(--ds-color-primary-25);
  }
}

:deep(.locations-selector) {
  > .uikit-input-wrapper {
    height: auto;
    min-height: 44px;
    padding: 4px 0 4px 12px;
    flex-wrap: wrap;

    > .prefix {
      max-width: 100%;
      flex-grow: 1;

      @media @bp-md-down {
        width: 100%;
      }
    }

    > input {
      min-width: 120px;
      width: auto;
      flex-grow: 1;
      height: 18px;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    > .suffix {
      width: 38px;

      svg {
        margin-left: 3px;
      }

      > .separator {
        margin-left: 4px;
        margin-right: 4px;
        background-color: var(--ds-color-gray-10);
        height: 80%;
        width: 1px;
      }
    }
  }
}

:deep(.city-range-selector) {
  width: 140px;

  .uikit-input-wrapper {
    border: none;
    height: 34px;

    > input {
      color: var(--ds-color-primary-100);
      font-weight: var(--ds-weight-semi-bold);
      height: 18px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}

:deep(.drawer-footer) {
  display: flex;
  flex-direction: row;
  gap: var(--ds-space-lg);

  .main-cta {
    flex-grow: 1;
  }
}

:deep(.u-gap-8) {
  gap: 8px;
}

.cta-separator {
  margin-left: 8px;
  margin-right: 8px;
  width: 1px;
  height: 24px;
  vertical-align: middle;
  background-color: var(--ds-color-gray-25);
  display: inline-block;
}
</style>
