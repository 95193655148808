<template>
  <uikit-drawer
    id="drawer-real-estate-offer-search"
    :width="640"
    :title="updateMode ? 'Réglages' : 'Sauvegarder ma recherche'"
    :sub-title="
      updateMode
        ? 'Souhaitez-vous recevoir des notifications pour toute annonce correspondant à ces critères ?'
        : ''
    "
  >
    <uikit-form-row one-column>
      <uikit-form-cell title="Nom personnalisé">
        <uikit-input-new
          id="name"
          name="name"
          placeholder="Saisir nom"
        />
      </uikit-form-cell>
    </uikit-form-row>
    <uikit-form-row>
      <uikit-form-cell>
        <span class="switch-title">Notifications par Mail</span>
      </uikit-form-cell>
      <uikit-form-cell>
        <uikit-switch
          id="notifyByEmail"
          name="notifyByEmail"
          :wording2="values.notifyByEmail ? 'Activé' : 'Désactivé'"
        />
      </uikit-form-cell>
    </uikit-form-row>
    <uikit-form-row>
      <uikit-form-cell>
        <span class="switch-title">Notifications Push</span>
      </uikit-form-cell>
      <uikit-form-cell>
        <uikit-switch
          id="notifyByPush"
          name="notifyByPush"
          :wording2="values.notifyByPush ? 'Activé' : 'Désactivé'"
        />
      </uikit-form-cell>
    </uikit-form-row>
    <template #footer>
      <uikit-button
        v-if="updateMode"
        type="secondary"
        :loading="isSubmitting"
        :disabled="isSubmitting"
        @click="handleCancel"
      >
        Annuler
      </uikit-button>
      <uikit-button
        class="main-cta"
        type="primary"
        :loading="isSubmitting"
        :disabled="isSubmitting"
        @click="handleSubmitForm"
      >
        Valider
      </uikit-button>
    </template>
  </uikit-drawer>
</template>

<script setup>
import {
  UikitButton,
  UikitDrawer,
  UikitFormCell,
  UikitFormRow,
  useDrawerStore,
  UikitInputNew,
  UikitSwitch,
} from '@hz/ui-kit'

import { useForm } from 'vee-validate'
import { useFormHelpers } from '../../helpers/form'

const props = defineProps({
  realEstateOfferSearch: {
    type: Object,
    default: null,
  },
  updateMode: {
    type: Boolean,
    default: false,
  },
  search: {
    type: Object,
    default: () => ({}),
  },
  onSuccess: {
    type: Function,
    default: () => {},
  },
})

const realEstateOfferSearchesApi = useRealEstateOfferSearchesApi()

const attrs = useAttrs()

const validationSchema = {
  name: { required: true },
  notifyByEmail: { selectRequired: true },
  notifyByPush: { selectRequired: true },
}

const formHelper = useFormHelpers(validationSchema)

const { handleSubmit, isSubmitting, values } = useForm({
  validationSchema,
  initialValues: props.updateMode
    ? formHelper.getOnlyFormFields(props.realEstateOfferSearch)
    : {
        name: '',
        notifyByEmail: true,
        notifyByPush: true,
      },
})

const close = () => {
  useDrawerStore().removeDrawer({ id: attrs.id })
}

const handleSubmitForm = handleSubmit(async (values) => {
  let search
  if (props.updateMode) {
    search = await realEstateOfferSearchesApi.updateById(props.realEstateOfferSearch.id, {
      ...values,
    })
  } else {
    search = await realEstateOfferSearchesApi.create({
      ...values,
      search: props.search,
    })
  }
  props.onSuccess(search)
  close()
})

const handleCancel = () => {
  close()
}
</script>

<style lang="less" scoped>
:deep(.drawer-footer) {
  display: flex;
  flex-direction: row;
  gap: var(--ds-space-lg);

  .main-cta {
    flex-grow: 1;
  }
}

.switch-title {
  font-size: 16px;
  font-weight: var(--ds-weight-semi-bold);
}
</style>
