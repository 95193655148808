export const realEstateAdRentStrategyOptions = [
  { label: 'Location', value: 'location' },
  { label: 'Touristique', value: 'touristique' },
  { label: 'Colocation', value: 'colocation' },
  { label: 'Location meublée', value: 'location-meublee' },
  { label: 'Location nue', value: 'location-nue' },
  { label: 'Immeuble rapport', value: 'immeuble-rapport' },
  { label: 'Bureaux', value: 'bureaux' },
  { label: 'Local commercial', value: 'local-commercial' },
  { label: 'Résidence de service', value: 'residence-service' },
  { label: 'Para-hôtelier', value: 'para-hotelier' },
  { label: 'Démembrement', value: 'demembrement' },
]

export const preferredRegimeTypes = [
  { label: "Déficit foncier au réel / SCI à l'IR", value: 'rr' },
  { label: 'Loc’Avantages', value: 'locAvantages2022' },
  { label: 'LMNP au réel', value: 'mer' },
  { label: "SCI/SARL à l'IS", value: 'sciis' },
  { label: 'LMNP micro-bic', value: 'me' },
  { label: 'Micro-foncier', value: 'mf' },
  { label: 'LMP au réel', value: 'mepr' },
  { label: 'Duflot', value: 'duf' },
  { label: 'Borloo ancien 30%', value: 'bo30' },
  { label: 'Borloo ancien 60%', value: 'bo60' },
  { label: 'Borloo ancien 70%', value: 'bo70' },
  { label: 'Denormandie location 6 ans', value: 'norm6' },
  { label: 'Denormandie location 9 ans', value: 'norm9' },
  { label: 'Denormandie location 12 ans', value: 'norm12' },
  { label: 'Pinel location 6 ans', value: 'pin6' },
  { label: 'Pinel location 9 ans', value: 'pin9' },
  { label: 'Pinel location 12 ans', value: 'pin12' },
  { label: 'Scellier 2009-2010', value: 'se2009' },
  { label: 'Scellier 2011', value: 'se2011' },
  { label: 'Scellier 2011 BBC', value: 'se2011b' },
  { label: 'Scellier 2012', value: 'se2012' },
  { label: 'Cosse ancien intermédiaire', value: 'cointer' },
  { label: 'Cosse ancien social', value: 'cosocial' },
  { label: 'Cosse ancien très social', value: 'cotressocial' },
  { label: 'Cosse ancien intermédiation', value: 'cointermed' },
  { label: 'Censi-bouvard', value: 'censi-bouvard' },
  { label: 'Malraux', value: 'malraux' },
]

export const realEstateTypes = [
  { label: 'Appartement', value: 'appartement' },
  { label: 'Maison', value: 'maison' },
  { label: 'Immeuble', value: 'immeuble' },
  { label: 'Parking', value: 'parking' },
]
export const realEstateFloorTypes = [
  { label: 'Rez-de-chaussée', value: 'rdc' },
  { label: 'Étage', value: 'not-rdc-not-last' },
  { label: 'Dernier', value: 'last' },
]

export const ownershipTypes = [
  { label: 'Mono-propriété', value: false },
  { label: 'Copropriété', value: true },
]

export const realEstateState = [
  { label: 'Ancien', value: false },
  { label: 'Neuf', value: true },
]

export const realEstateSpecificities = [
  { label: 'Avec travaux', value: 'renovation' },
  { label: 'Déja loué', value: 'rented' },
]

export const realEstateEnergyClassOptions = [
  { label: 'A', value: 'a' },
  { label: 'B', value: 'b' },
  { label: 'C', value: 'c' },
  { label: 'D', value: 'd' },
  { label: 'E', value: 'e' },
  { label: 'F', value: 'f' },
  { label: 'G', value: 'g' },
  { label: 'Vierge', value: 'vierge' },
]

export const realEstateGesOptions = [
  { label: 'A', value: 'a' },
  { label: 'B', value: 'b' },
  { label: 'C', value: 'c' },
  { label: 'D', value: 'd' },
  { label: 'E', value: 'e' },
  { label: 'F', value: 'f' },
  { label: 'G', value: 'g' },
  { label: 'Vierge', value: 'vierge' },
]

export const citySizeTypes = [
  { label: 'Petite', value: 'small' },
  { label: 'Moyenne', value: 'medium' },
  { label: 'Grande', value: 'large' },
]
