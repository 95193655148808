<template>
  <uikit-drawer
    id="drawer-my-real-estate-offer-favorites"
    ref="drawer"
    size="MEDIUM"
    title="Favoris"
  >
    <template v-if="offersToDisplay.length">
      <tub-section-offers-with-pagination
        :current-page="currentPage"
        :pages="pages"
        :offers="offersToDisplay"
        :skeleton="isLoading"
        :nb-columns="2"
        @page-change="pageChangeHandler"
        @card-click="handleCardClick"
        @remove-favorite="handleRemoveFavorite"
      >
        <uikit-infos>
          Si l’annonce n’est plus active sur le site, elle disparaîtra automatiquement de votre sélection.
        </uikit-infos>
      </tub-section-offers-with-pagination>
    </template>
    <uikit-infos v-else>
      Aucun favori pour le moment !
      <br>
      Ajoutez en dés maintenant en cliquant sur l'icône coeur de vos annonces préférées.
    </uikit-infos>
  </uikit-drawer>
</template>

<script setup>
import { UikitDrawer, UikitInfos, useRemoteDatasource } from '@hz/ui-kit'

const drawer = ref(null)

const tubApi = useTubApi()
const dataSource = useRemoteDatasource(tubApi.find)

const isLoading = ref(true)
const currentPage = ref(1)
const { data: offers, pages } = dataSource.getDataAndMetas()

dataSource.setGlobalFilters({ favorite: true })
dataSource.setPage(currentPage.value)
dataSource.setPageSize(10)

const offersToDisplay = computed(() => {
  if (isLoading.value) {
    return Array.from({ length: 10 }, (_, i) => ({ id: i, skeleton: true }))
  }

  return offers.value
})

dataSource.load()
isLoading.value = false

const pageChangeHandler = async (page) => {
  currentPage.value = page
  isLoading.value = true
  dataSource.setPage(page)
  await dataSource.load()
  isLoading.value = false
}

const handleCardClick = () => {
  drawer.value.close()
}

const handleRemoveFavorite = async () => {
  isLoading.value = true
  await dataSource.load()
  isLoading.value = false
}
</script>

<style lang="less" scoped>
:deep(.drawer-body) {
  padding-left: var(--ds-space-xl);
  padding-right: var(--ds-space-xl);
}

.empty-state {
  color: var(--ds-color-gray-100);
  font-size: var(--ds-font-md);
  font-weight: var(--ds-weight-regular);
}

:deep(.offer-with-pagination) {
  padding-top: 0;
}
</style>
