import { defineStore } from 'pinia'

export const usePushStore = defineStore('home-push', {
  state: () => ({
    browserPermissionGranted: false,
    userIsOptIn: false,
    detectionDone: false,
  }),
  actions: {
    resetDetection() {
      this.detectionDone = false
      this.userIsOptIn = false
      this.browserPermissionGranted = false
    },
    startDetection() {
      window.onmessage = (e) => {
        if (e.data?.name === 'hz-notification-push-check') {
          this.detectionDone = true
          this.userIsOptIn = e.data?.userIsOptIn
          this.browserPermissionGranted = e.data?.browserPermissionGranted
        }
      }
    },
  },
})
