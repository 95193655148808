<template>
  <uikit-drawer id="drawer-my-real-estate-offer-searches" ref="drawer" title="Mes recherches">
    <div v-if="searches.length" class="searches">
      <uikit-infos v-if="displayNotificationNotActiveWarning" type="warning">
        Vous avez configuré certaines de vos recherches pour recevoir des notifications push.
        <br />
        <br />
        Celles-ci ne sont actuellement pas activées sur votre navigateur.
      </uikit-infos>

      <uikit-button
        v-if="displayNotificationNotActiveWarning"
        type="primary"
        @click="handleActiveNotification"
      >
        Activer les notifications
      </uikit-button>

      <template v-for="search in searches" :key="search.id">
        <div class="search-card">
          <div class="header">
            <div class="left">
              <div class="title">{{ search.name }}</div>
              <NuxtLink class="link" :to="getSearchToNavigation(search)">
                <uikit-button type="link" @click="handleClose">
                  <template v-if="search.newOffersSinceLAstSeen > 0">
                    {{ search.newOffersSinceLAstSeen }} nouveaux résultats
                  </template>
                  <template v-else>Aucun nouveaux résultats</template>
                </uikit-button>
              </NuxtLink>
            </div>
            <div class="right">
              <uikit-button type="danger" @click="handleDeleteSearch(search)">
                <template #icon>
                  <uikit-trash-icon />
                </template>
              </uikit-button>
            </div>
          </div>
          <div class="body">
            <div
              v-for="criteria in getDisplayableCriteria(search)"
              :key="criteria.key"
              class="u-dis-flex u-align-center"
            >
              <span>{{ criteria.label }}</span>
              <template v-if="criteria.displayValue">
                <svg
                  class="u-mr-xs u-ml-xs"
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="3"
                  viewBox="0 0 2 3"
                  fill="none"
                >
                  <circle cx="1" cy="1.5" r="1" fill="#606266" />
                </svg>
                <span :class="{ bullet: criteria.key === 'more-filters' }">{{ criteria.displayValue }}</span>
              </template>
            </div>
          </div>
          <div class="ctas">
            <NuxtLink class="link" :to="getSearchToNavigation(search)">
              <uikit-button type="link" @click="handleClose">
                Voir et modifier
                <template #icon>
                  <uikit-eye-icon />
                </template>
              </uikit-button>
            </NuxtLink>
            <div class="separator" />
            <uikit-button type="link" @click="handleOpenEditSearch(search)">
              Réglages
              <template #icon>
                <uikit-cog-icon />
              </template>
            </uikit-button>
          </div>
        </div>
      </template>
    </div>
    <uikit-infos v-else>
      Aucune recherche sauvegardée pour le moment !
      <br />
      Ne manquez plus aucune opportunité !
      <br />
      Sauvegardez vos recherches et recevez des notifications dès qu'une nouvelle annonce correspond à vos
      critères !
    </uikit-infos>
  </uikit-drawer>
</template>

<script setup>
import {
  UikitButton,
  UikitDrawer,
  UikitCogIcon,
  UikitEyeIcon,
  UikitTrashIcon,
  useModalStore,
  UikitInfos,
} from '@hz/ui-kit'
import { useTubStore } from '../../stores/tub'
import { usePushStore } from '../../stores/push'
import {
  citySizeTypes,
  preferredRegimeTypes,
  realEstateAdRentStrategyOptions,
  realEstateEnergyClassOptions,
  realEstateFloorTypes,
  realEstateGesOptions,
  realEstateSpecificities,
  realEstateTypes,
} from '../../helpers/real-estates'
import { useUrls } from '../../composables/urls'

const drawer = ref(null)
const urls = useUrls()

const realEstateOfferSearchesApi = useRealEstateOfferSearchesApi()
const searches = ref([])

const tubStore = useTubStore()
const pushStore = usePushStore()

const notificationActive = computed(() => pushStore.browserPermissionGranted && pushStore.userIsOptIn)

const displayNotificationNotActiveWarning = computed(() => {
  const searchWithPush = searches.value.filter((search) => search.notifyByPush)

  if (!pushStore.detectionDone || searchWithPush.length === 0) {
    return false
  }

  return !notificationActive.value
})

const close = () => {
  drawer.value.close()
}

const handleClose = () => {
  close()
}

const handleActiveNotification = () => {
  window.open(urls.notificationPushParametersUrl(), '_blank').focus()
}

const fetchSearches = async () => {
  const response = await realEstateOfferSearchesApi.getMySearches()
  searches.value = response.data
}

const getSearchToNavigation = (search) => {
  return {
    name: 'biens-rentables-custom-search',
    query: {
      searchId: search.id,
    },
  }
}

const minMaxDisplayValue = (value, unit) => {
  if (value.$gte && value.$lte) {
    return `${value.$gte}${unit} à ${value.$lte}${unit}`
  } else if (value.$lte) {
    return `Max. ${value.$lte}${unit}`
  } else if (value.$gte) {
    return `Min. ${value.$gte}${unit}`
  }
}

const listDisplayValue = (options, value) => {
  return value.map((key) => options.find((item) => item.value === key).label).join(', ')
}

const getSearchCriteria = (search) => {
  return Object.entries(search.search.filters)
    .map(([key, value]) => {
      let order = 10000
      let label = key
      let displayValue = value

      switch (key) {
        case 'locations': {
          order = 1
          label = value.length === 1 ? 'Ville' : 'Villes'

          let description = ''
          const locations = value

          const nbCities = locations?.length ?? 0
          if (nbCities === 0) {
            description = 'Toute la France'
          } else {
            if (nbCities > 0) {
              if (nbCities === 1) {
                const distance = locations[0].distance
                const ville = locations[0].ville
                const cp = locations[0].cp

                if (distance === 'region') {
                  description = `Région - ${ville} (${cp})`
                } else if (distance === 'department') {
                  description = `Département - ${ville} (${cp})`
                } else if (distance === 'all-city') {
                  description = `${ville} (Toute la ville)`
                } else if (distance !== 0 && distance !== '0') {
                  description = `${ville} (${cp}) à ${distance} km`
                } else {
                  description += ville
                }
              } else {
                description += locations[0].ville
              }
            }
            if (nbCities > 1) {
              description += `, ${locations[1].ville}`
            }
            if (nbCities > 2) {
              description += ', ...'
            }
          }

          displayValue = description
          break
        }

        case 'rendement': {
          order = 2
          label = 'Rendement brut'
          displayValue = minMaxDisplayValue(value, '%')
          break
        }

        case 'prix': {
          order = 3
          label = 'Prix'
          displayValue = minMaxDisplayValue(value, '€')
          break
        }

        case 'hzExclusive': {
          order = 4
          label = "Type d'annonces"
          displayValue = value ? 'Exclusives' : 'Toutes'
          break
        }

        case 'citySize': {
          order = 4
          label = 'Taille de ville'
          displayValue = listDisplayValue(citySizeTypes, value)
          break
        }

        case 'prixm2': {
          order = 5
          label = 'Prix m2'
          displayValue = minMaxDisplayValue(value, '€')
          break
        }

        case 'loyer': {
          order = 5
          label = 'Loyer'
          displayValue = minMaxDisplayValue(value, '€')
          break
        }

        case 'recommendedStrategies': {
          order = 6
          label = 'Stratégie de location'
          displayValue = listDisplayValue(realEstateAdRentStrategyOptions, value)
          break
        }

        case 'preferredRegime': {
          order = 7
          label = 'Régime fiscal préconisé'
          displayValue = listDisplayValue(preferredRegimeTypes, value)
          break
        }

        case 'surface': {
          order = 8
          label = 'Surface'
          displayValue = minMaxDisplayValue(value, 'm2')
          break
        }

        case 'nbpieces': {
          order = 9
          label = 'Nombre de pièces'
          displayValue = minMaxDisplayValue(value, '')
          break
        }

        case 'floor': {
          order = 10
          label = 'Etage du bien'
          displayValue = listDisplayValue(realEstateFloorTypes, value)
          break
        }

        case 'type': {
          order = 11
          label = 'Type de logement'
          displayValue = listDisplayValue(realEstateTypes, value)
          break
        }

        case 'coOwnership': {
          order = 12
          label = 'Propriété'
          displayValue = value ? 'Copropriété' : 'Mono-propriété'
          break
        }

        case 'neuf': {
          order = 13
          label = 'Etat du bien'
          displayValue = value ? 'Neuf' : 'Ancien'
          break
        }

        case 'specificities': {
          order = 14
          label = 'Sélectionner les spécificités'
          displayValue = listDisplayValue(realEstateSpecificities, value)
          break
        }

        case 'energyClass': {
          order = 14
          label = 'DPE'
          displayValue = listDisplayValue(realEstateEnergyClassOptions, value)
          break
        }

        case 'ges': {
          order = 15
          label = 'GES'
          displayValue = listDisplayValue(realEstateGesOptions, value)
          break
        }

        case 'freshness': {
          order = 16
          label = 'Ancienneté des annonces'
          displayValue = minMaxDisplayValue(value, '')
          break
        }

        case 'travaux': {
          order = 17
          label = 'Avec travaux'
          displayValue = null
          break
        }

        case 'locataireInside': {
          order = 18
          label = 'Avec locataires'
          displayValue = null
          break
        }
      }

      return {
        key,
        order,
        label,
        displayValue,
      }
    })
    .sort((a, b) => a.order - b.order)
}

const getDisplayableCriteria = (search) => {
  const criteria = getSearchCriteria(search)

  if (criteria.length <= 3) {
    return criteria
  }

  return [
    ...criteria.slice(0, 3),
    {
      key: 'more-filters',
      label: 'Autres filtres',
      displayValue: criteria.length - 3,
    },
  ]
}

const handleDeleteSearch = async (search) => {
  useModalStore().confirm({
    title: 'Supprimer la recherche',
    subTitle: 'Êtes-vous sûr de vouloir supprimer cette recherche ?',
    onConfirmed: async () => {
      await realEstateOfferSearchesApi.deleteById(search.id)
      await fetchSearches()
    },
  })
}

const handleOpenEditSearch = async (search) => {
  await tubStore.openEditSearchDrawer(search, {
    onSuccess: async () => {
      await fetchSearches()
    },
  })
}

onMounted(async () => {
  await fetchSearches()
})
</script>

<style lang="less" scoped>
#hz-notification-push-check {
  display: none;
}

.searches {
  display: flex;
  flex-direction: column;
  gap: var(--ds-space-xl);
}
.search-card {
  border-radius: var(--ds-space-sm);
  border: 1px solid var(--ds-color-gray-25);
  background: var(--ds-color-white-100);
  display: flex;
  padding: var(--ds-space-lg) var(--ds-space-2lg);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--ds-space-md);
}
.header {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .title {
    font-size: var(--ds-font-md);
    font-weight: var(--ds-weight-semi-bold);
  }

  .link {
    font-size: var(--ds-font-sm);
  }
}
.body {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: var(--ds-font-sm);
  color: var(--ds-color-gray-50);
}
.ctas {
  display: flex;
  align-items: center;

  .separator {
    width: 1px;
    height: 24px;
    background: var(--ds-color-gray-25);
    margin-left: var(--ds-space-md);
    margin-right: var(--ds-space-md);
  }
}

.bullet {
  height: 18px;
  width: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--ds-color-white-100);
  font-size: 12px;
  font-weight: var(--ds-weight-semi-bold);
  background-color: var(--ds-color-primary-100);
  border-radius: 50%;
}
</style>
