import { hooksFetch, hooksFetchPost, hooksFetchDelete, hooksFetchPatch } from '../core/api'

export const useRealEstateOfferSearchesApi = () => ({
  getMySearches: async () => {
    return await hooksFetch('/v2/real-estate-ads/searches?filter=%7B%7D&limit=100')
  },
  create: async (body) => {
    return await hooksFetchPost('/v2/real-estate-ads/searches', { body })
  },
  getById: async (id) => {
    return await hooksFetch(`/v2/real-estate-ads/searches/${id}`)
  },
  updateById: async (id, body) => {
    return await hooksFetchPatch(`/v2/real-estate-ads/searches/${id}`, { body })
  },
  flagAsSeenById: async (id) => {
    return await hooksFetchPatch(`/v2/real-estate-ads/searches/${id}/seen`, { body: {} })
  },
  deleteById: async (id) => {
    return await hooksFetchDelete(`/v2/real-estate-ads/searches/${id}`)
  },
})
