export const useFormHelpers = (validationSchema) => {
  return {
    getOnlyFormFields: (dataObject) => {
      return Object.keys(validationSchema).reduce((acc, key) => {
        acc[key] = dataObject[key]

        return acc
      }, {})
    },
  }
}
