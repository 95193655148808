<template>
  <div class="offer-with-pagination">
    <slot />
    <div
      class="offers"
      :class="{
        'allow-two-columns': nbColumns >= 2,
        'allow-three-columns': nbColumns >= 3,
      }"
    >
      <template
        v-for="(offer, index) in offers"
        :key="offer.id"
      >
        <tub-turn-key-card v-if="index === 2 && displayMarketingCard" />
        <tub-offer-card
          class="offer"
          :offer="offer"
          :skeleton="skeleton"
          @card-click="handleCardClick"
          @remove-favorite="handleRemoveFavorite"
        />
      </template>
    </div>
    <div class="pagination">
      <uikit-pagination
        :current="currentPage"
        :pages="pages"
        @on-change="pageChangeHandler"
      />
    </div>
  </div>
</template>

<script setup>
import { UikitPagination } from '@hz/ui-kit'

defineProps({
  currentPage: {
    type: Number,
    required: true,
  },
  pages: {
    type: Number,
    required: true,
  },
  offers: {
    type: Array,
    required: true,
  },
  skeleton: {
    type: Boolean,
    default: false,
  },
  displaySaveSearch: {
    type: Boolean,
    default: false,
  },
  updateSaveSearch: {
    type: Boolean,
    default: false,
  },
  displayMarketingCard: {
    type: Boolean,
    default: false,
  },
  nbColumns: {
    type: Number,
    default: 3,
  },
})

const emit = defineEmits(['pageChange', 'card-click', 'remove-favorite'])

const pageChangeHandler = (page) => {
  emit('pageChange', page)
}

const handleCardClick = (offer) => {
  emit('card-click', offer)
}

const handleRemoveFavorite = (offer) => {
  emit('remove-favorite', offer)
}
</script>

<style lang="less" scoped>
.offer-with-pagination {
  .offers {
    padding-top: 20px;
    padding-bottom: 20px;

    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(1, minmax(342px, 1fr)); //(max 753)
    grid-auto-rows: minmax(240px, auto);

    &.allow-two-columns {
      @media only screen and (min-width: 754px) {
        grid-template-columns: repeat(2, minmax(342px, 1fr)); // (min 754) (max 1225)
      }
    }
    &.allow-three-columns {
      @media only screen and (min-width: 1226px) {
        grid-template-columns: repeat(3, minmax(342px, 1fr)); // (min 1226)
      }
    }

    :deep(.tub-offer-card) {
      width: auto;
      height: auto;
      aspect-ratio: 342/240;

      .cover {
        height: calc(100% - 100px);
      }
    }
  }
}

@media @bp-desktop {
  .offer-with-pagination {
    padding-top: 52px;
  }
}
</style>
